
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    /* eslint-disable no-return-assign */
/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import useVerticalIndex from '@/src/hooks/useVerticalIndex';
import { trackEvent, trackClickEvent } from '../../../../utils/analytics';
import Page from '../../../../components/CarouselPage';
import Pagination from '../../../../components/CarouselPagination';
import CarouselButton from '../../../../components/CarouselButton';
import Image from '../../../../components/Image';
import CarouselItem from './CarouselItem';
import { findHubName } from '../../../../utils/hubs';
import flourish from './imgs/flourish.svg';
import { romanize } from '../../../../utils';
import s from './CollectionCarousel.module.scss';
import EcommerceCarouselItem from './EcommerceCarouselItem';
export const VARIANT_ECOMMERCE = 'ecommerce';
const propTypes = {
    eyebrow: PropTypes.string,
    content: PropTypes.array,
    variant: PropTypes.oneOf([VARIANT_ECOMMERCE, '']),
    theme: PropTypes.string,
    subhead1: PropTypes.string,
    subhead2: PropTypes.string,
    pageName: PropTypes.string
};
const componentName = 'CollectionCarousel';
const CollectionCarousel = ({ eyebrow = '', content = [], theme = 'Dark', subhead1 = '', subhead2 = '', variant = '', pageName = '', }) => {
    const { componentRef, verticalIndex } = useVerticalIndex();
    const speed = 400;
    // Used to fix an issue where swiping triggered click event
    let dragging = false;
    const afterChange = /* istanbul ignore next */ () => {
        dragging = false;
    };
    const beforeChange = /* istanbul ignore next */ () => {
        dragging = true;
        setTimeout(() => {
            afterChange();
        }, speed);
    };
    const sliderSettings = {
        customPaging: (i) => <Page label={romanize(i + 1)} theme={theme.toLowerCase()}/>,
        appendDots: (dots) => (<>
        <div className={[s.upperPagination, variant === VARIANT_ECOMMERCE ? s.ecommerce : ''].join(' ')}>
          <Pagination pages={dots} containerClass={s.pages}/>
        </div>
      </>),
        speed,
        className: 'slider variable-width',
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true,
        arrows: false,
        beforeChange,
        afterChange
    };
    const settings = { ...sliderSettings };
    const [activeIndex, setActiveIndex] = React.useState(0);
    let collectionSlider = useRef(null);
    let buttonTheme = theme === 'Light' ? 'dark' : 'light';
    if (variant === VARIANT_ECOMMERCE) {
        buttonTheme = 'purple';
    }
    const handleSlideChange = (index) => {
        let newIndex = index;
        if (index > content.length - 1) {
            newIndex = 0;
        }
        if (index < 0) {
            newIndex = content.length - 1;
        }
        if (variant === VARIANT_ECOMMERCE) {
            trackEvent('Carousel Item Swiped', {
                carousel_index: newIndex + 1,
                carousel_name: 'Exclusive Savings',
                content_id: content[newIndex] && content[newIndex].contentfulId ? content[newIndex].contentfulId : '',
                content_name: content[newIndex] && content[newIndex].displayTitle ? content[newIndex].displayTitle : '',
                content_type: content[newIndex] && content[newIndex].contentTypeId
                    ? content[newIndex].contentTypeId
                    : '',
                location: pageName,
                previous_carousel_index: activeIndex + 1
            });
        }
        else {
            trackEvent('Carousel Item Swiped', {
                carousel_name: 'Collection Carousel',
                previous_carousel_index: activeIndex + 1,
                carousel_index: newIndex + 1,
                location: 'Collection',
                carousel_item_text: content[newIndex] && content[newIndex].displayTitle ? content[newIndex].displayTitle : '',
                carousel_item_id: content[newIndex] && content[newIndex].contentfulId ? content[newIndex].contentfulId : ''
            });
        }
        setActiveIndex(newIndex);
    };
    return (<div className={[s.root, variant === VARIANT_ECOMMERCE ? s.ecommerce : ''].join(' ')} ref={componentRef}>
      <h2 className={[
            s.eyebrow,
            theme === 'Light' ? s.eyebrowDark : '',
            variant === VARIANT_ECOMMERCE ? s.centered : '',
        ].join(' ')}>
        {eyebrow}
      </h2>
      {subhead1 && (<section>
          <div className={s.flourish}>
            <Image imageSet={[
                {
                    src: flourish
                },
            ]} alt="Flourish"/>
          </div>
          <div className={s.subhead1}>{subhead1}</div>
          {subhead2 && <div className={s.subhead2}>{subhead2}</div>}
        </section>)}
      <div className={s.sliderInner}>
        <div className={[s.controls, variant === VARIANT_ECOMMERCE ? s.ecommerce : ''].join(' ')}>
          <CarouselButton type="prev" theme={buttonTheme} onClick={() => {
            handleSlideChange(activeIndex - 1);
            collectionSlider.slickPrev();
        }}/>
          <CarouselButton type="next" theme={buttonTheme} onClick={() => {
            handleSlideChange(activeIndex + 1);
            collectionSlider.slickNext();
        }}/>
        </div>
        <div className={s.slider}>
          <Slider {...settings} ref={(slider) => (collectionSlider = slider)}>
            {variant === VARIANT_ECOMMERCE &&
            content.map((c, i) => {
                return (<EcommerceCarouselItem key={i} image={c.productImage?.file?.url} productTitle={c.productTitle} productURL={c.productURL} productPrice={c.productPrice} productPriceUS={c.productPriceUS} goldPrice={c.goldPrice} goldPriceUS={c.goldPriceUS} goldDiscount={c.goldDiscount} onItemClick={() => {
                        trackClickEvent({
                            destination_url: c.productURL,
                            label: c.displayTitle,
                            content_name: c.productTitle,
                            content_type: c.contentTypeId,
                            content_id: c.contentfulId,
                            location: componentName,
                            vertical_index: verticalIndex,
                            horizontal_index: i
                        });
                    }}/>);
            })}
            {variant !== VARIANT_ECOMMERCE &&
            content.map((c, i) => {
                const articleLink = c.tags ? `/${findHubName(c.tags)}/${c.externalId}` : '';
                return (<CarouselItem image={c.teaserImage?.image?.file?.url ?? c.mainImage?.image?.file?.url ?? ''} title={c.displayTitle} description={c.intro} key={i} link={articleLink} theme={theme} onItemClick={(e) => {
                        /* istanbul ignore if */
                        if (dragging) {
                            e.preventDefault();
                        }
                        else {
                            trackClickEvent({
                                destination_url: articleLink,
                                label: c.displayTitle,
                                content_name: c.displayTitle,
                                content_type: c.contentTypeId,
                                content_id: c.contentfulId,
                                location: componentName,
                                vertical_index: verticalIndex,
                                horizontal_index: i
                            });
                        }
                    }}/>);
            })}
          </Slider>
        </div>
      </div>
    </div>);
};
CollectionCarousel.propTypes = propTypes;
export default CollectionCarousel;

    async function __Next_Translate__getStaticProps__1930804e66c__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionCarousel/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1930804e66c__ as getStaticProps }
  