import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalState as GenericPageGlobalState } from '../../globalStates/GenericPageState';
import { ReactComponent as Next } from './next.svg';
import { ReactComponent as NextDark } from './next-dark.svg';
import { ReactComponent as Prev } from './previous.svg';
import { ReactComponent as PrevDark } from './previous-dark.svg';
import { ReactComponent as PrevPurple } from './previous-purple.svg';
import { ReactComponent as NextPurple } from './next-purple.svg';
import s from './CarouselButton.module.scss';

const propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  theme: PropTypes.oneOf(['dark', 'light', 'purple']),
  containerClassName: PropTypes.string,
};

const CarouselButton = ({
  type = 'prev',
  onClick = () => {},
  theme = 'dark',
  containerClassName = '',
}) => {
  const [isGenericPage] = GenericPageGlobalState('isGenericPage');
  return (
    <div
      role="button"
      tabIndex="-1"
      aria-label="Carousel button"
      className={[s.button, s[theme], containerClassName, `carouselButton_${type}`].join(' ')}
      onClick={onClick}
      data-testid="carousel-button"
      onKeyPress={onClick}
    >
      {!isGenericPage && (
        <>
          {theme === 'dark' && (type === 'next' ? <NextDark className={s.hover} /> : <PrevDark />)}
          {theme === 'light' && (type === 'next' ? <Next /> : <Prev />)}
          {theme === 'purple' && (type === 'next' ? <NextPurple /> : <PrevPurple />)}
        </>
      )}
      {isGenericPage && (
        <span className={s.genericArrow}>{type === 'next' ? <Next /> : <Prev />}</span>
      )}
    </div>
  );
};

CarouselButton.propTypes = propTypes;
export default CarouselButton;
