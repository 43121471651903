import { createGlobalState } from 'react-hooks-global-state';

const initialState = {
  shouldStick: true,
  shouldUnstick: false,
  locale: '',
  countryInfo: {
    allowed: '',
    termsUrl: '',
    loading: true,
  },
};

const { setGlobalState, useGlobalState, getGlobalState } = createGlobalState(initialState);

export const setShouldStick = (shouldStick) => {
  setGlobalState('shouldStick', shouldStick);
};

export const setShouldUnstick = (shouldUnstick) => {
  setGlobalState('shouldUnstick', shouldUnstick);
};

export const setCountryInfo = (countryInfo) => {
  setGlobalState('countryInfo', countryInfo);
};

export const setLocale = (locale) => {
  setGlobalState('locale', locale);
};

export { useGlobalState, getGlobalState };
