
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../../../../components/Image';
import AnchorLink from '../../../../../components/AnchorLink';
import s from './EcommerceCarouselItem.module.scss';
import badge from '../imgs/oval.svg';
import { getGlobalState } from '../../../../../globalStates/HPFCState';
const propTypes = {
    image: PropTypes.string,
    productTitle: PropTypes.string,
    productURL: PropTypes.string,
    productPrice: PropTypes.number,
    productPriceUS: PropTypes.number,
    goldPrice: PropTypes.number,
    goldPriceUS: PropTypes.number,
    goldDiscount: PropTypes.number,
    onItemClick: PropTypes.func
};
const EcommerceCarouselItem = ({ image = '', productTitle = '', productURL = '', productPrice = null, productPriceUS = null, goldPrice = null, goldPriceUS = null, goldDiscount = 0, onItemClick = () => { }, }) => {
    const locale = getGlobalState('locale');
    return (<article className={s.root}>
      <AnchorLink href={productURL} onClick={onItemClick} external>
        <Image imageSet={[
            {
                src: `${image}?w=300&h=300&fit=fill&f=top`
            },
        ]} gradient={false} className={s.productImage} lazy/>
        <div className={s.badge}>
          <Image imageSet={[
            {
                src: badge
            },
        ]} alt="Flourish" lazy/>
          <div className={s.percentageOff}>
            <div className={s.discountContainer}>
              <div className={s.discount}>{`${goldDiscount}%`}</div>
              <div className={s.offText}>off</div>
            </div>
          </div>
        </div>
        <div className={s.productTitle}>{productTitle}</div>
        <div className={s.productPrice} data-testid="product-price">
          {locale === 'US' && `$${productPriceUS}`}
          {locale !== 'US' && `£${productPrice}`}
        </div>
        <div className={s.goldPrice} data-testid="gold-price">
          {locale === 'US' && `$${goldPriceUS}`}
          {locale !== 'US' && `£${goldPrice}`}
        </div>
        <div className={s.goldText}>GOLD PRICE</div>
      </AnchorLink>
    </article>);
};
EcommerceCarouselItem.propTypes = propTypes;
export default EcommerceCarouselItem;

    async function __Next_Translate__getStaticProps__1930805a5f6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: 'src//Collection/components/CollectionCarousel/EcommerceCarouselItem/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__1930805a5f6__ as getStaticProps }
  