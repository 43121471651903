import React from 'react';
import PropTypes from 'prop-types';
import Page from '../CarouselPage';
import { romanize } from '../../utils';
import s from './CarouselPagination.module.scss';

const propTypes = {
  className: PropTypes.string,
  containerClass: PropTypes.string,
  pages: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
  longPagination: PropTypes.bool,
};

const Pagination = ({
  className = '',
  pages = [],
  containerClass = '',
  longPagination = false,
}) => {
  const filteredPages = pages.filter((page) => {
    if (!longPagination || page.props.className.includes('slick-active')) {
      return true;
    }
    return false;
  });
  if (longPagination) {
    const label = (
      <span>
        <span className="prefix">/</span>
        {romanize(pages.length)}
      </span>
    );
    const lastItem = (
      <li key="last">
        <Page theme="light" label={label} />
      </li>
    );
    filteredPages.push(lastItem);
  }
  return (
    <div
      className={[
        s.pagination,
        className,
        containerClass,
        longPagination ? 'long-pagination' : '',
      ].join(' ')}
    >
      <ul className={s.pages}>{filteredPages}</ul>
    </div>
  );
};

Pagination.propTypes = propTypes;
export default Pagination;
