import React from 'react';
import PropTypes from 'prop-types';
import { useGlobalState as GenericPageGlobalState } from '../../globalStates/GenericPageState';
import s from './CarouselPage.module.scss';

const propTypes = {
  className: PropTypes.string,
  onPageClick: PropTypes.func,
  theme: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

const Page = ({ className = '', onPageClick = () => {}, theme = 'dark', label = '' }) => {
  const [isGenericPage] = GenericPageGlobalState('isGenericPage');
  return (
    <button
      type="button"
      className={[className, s.page, s[theme], isGenericPage ? s.isGenericPage : ''].join(' ')}
      onClick={onPageClick}
    >
      {label}
    </button>
  );
};

Page.propTypes = propTypes;
export default Page;
